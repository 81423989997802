import React from 'react';

function Mikvaot() {
  return (
    <section id="mikvaot" className="section">
      <h2>Mikvaot</h2>
      <div className="mikvaot-content">
        <div className="mikvah-info">
          <p>
            If taking a dip in Lake Washington isn't your jam (my favorite spot is <a href="https://goo.gl/maps/tXthFRoD3RK6P4Sd7" target="_blank" rel="noopener noreferrer">Magnuson Park Boat Launch</a>), there are mikvaot in the North End, Seward Park, Bellevue and Issaquah.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Mikvaot; 