import React from 'react';
  
function Visiting() {
  return (
    <section id="visiting" className="section">
      <h2>Visiting</h2>
      <div className="visiting-content">
        <div className="visiting-info">
          <p>
            This guide is not targeted towards visitors to Seattle.
            To address a common question received from folks traveling to Seattle, the{' '}
            <a href="https://www.silvercloud.com/university/" target="_blank" rel="noopener noreferrer">Silver Cloud Hotel</a>{' '}
            in University District is inside the North End Eruv and has proximity to Orthodox congregations.
            Especially during the summer, we are blessed to have many visitors looking to take advantage of the beautiful nature, weather, cruises, etc.
            If you are not intending to move to Seattle and still want to ask an individual question beyond what has already been addressed here, please consider making a donation to a Seattle Jewish institution of your choice to support the local infrastructure & community.
            Feel free to ask for recommended organizations in your outreach.
          </p>
        </div>
      </div>
    </section>
  );
}
  
export default Visiting; 