import React from 'react';

function Networking() {
  return (
    <section id="networking" className="section">
      <h2>Networking</h2>
      <div className="networking-content">
        <div className="networking-info">
          <p>There's an active community of Facebook groups serving the Seattle Jewish community.</p>
          
          <ul>
            <li>
              Ask for favors or offer help to others at{' '}
              <a href="https://www.facebook.com/groups/seattlechesedrefuah/" target="_blank" rel="noopener noreferrer">
                Seattle Chesed and Refuah
              </a>.
            </li>
            
            <li>
              Use the{' '}
              <a href="https://www.facebook.com/groups/269960016533787/" target="_blank" rel="noopener noreferrer">
                Kosher Deals in Seattle
              </a>{' '}
              to find kosher food bargains across town. Every year for Pesach there's a{' '}
              <a href="https://www.facebook.com/groups/1392729137711935" target="_blank" rel="noopener noreferrer">
                group
              </a>{' '}
              dedicated to Passover deals.
            </li>
            
            <li>
              <a href="https://www.facebook.com/groups/313706742351290/" target="_blank" rel="noopener noreferrer">
                Seattle Jewish B/S/T
              </a>{' '}
              is a place to give away/sell items within the Jewish community.
            </li>
            
            <li>
              <a href="https://www.facebook.com/MoHoSeattle/" target="_blank" rel="noopener noreferrer">
                Moishe House Seattle
              </a>{' '}
              offers many programs for young professionals. As of November 2021, there is a Shomer Shabbat/Kashrut Moishe House in NE Seattle.
            </li>
            
            <li>
              There are a number of organizations targeted towards the Israeli community such as{' '}
              <a href="https://www.facebook.com/IsraeliAmericanCouncilSeattle/" target="_blank" rel="noopener noreferrer">
                IAC Seattle
              </a>{' '}
              and the "Israelis in Seattle" Facebook{' '}
              <a href="https://www.facebook.com/groups/israelisinseattle/" target="_blank" rel="noopener noreferrer">
                page
              </a>.
            </li>
            
            <li>
              <a href="https://buynothingproject.org/find-a-group/" target="_blank" rel="noopener noreferrer">
                Buy Nothing
              </a>{' '}
              is a cool organization where you can give and receive loads of free stuff. It's recycling to the next level and super convenient for giving away that extra desk that doesn't fit in your apartment.
            </li>
            
            <li>
              There's a group dedicated to growing the observant Jewish community in Seattle called{' '}
              <a href="https://www.facebook.com/groups/869724439715252/?ref=group_header" target="_blank" rel="noopener noreferrer">
                Seattle Orthodox Jewish Community Growth Initiative
              </a>.
            </li>
            
            <li>
              If you're into Jewish meditation, we have a group for that too! See the{' '}
              <a href="https://www.facebook.com/groups/SeattleJewishMeditationInitiative/" target="_blank" rel="noopener noreferrer">
                Seattle Jewish Meditation Initiative
              </a>.
            </li>
            
            <li>
              The private{' '}
              <a href="https://www.facebook.com/groups/1227036100760601" target="_blank" rel="noopener noreferrer">
                Seattle Jewish Recent Grads (&amp; Interns)
              </a>{' '}
              is another good resource for folks new or moving to Seattle.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Networking; 