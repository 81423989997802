import React from 'react';

function Header() {
  return (
    <header className="App-header" role="banner">
      <h1>Jewish Seattle Guide</h1>
      <nav role="navigation" aria-label="Main navigation">
        <ul>
          <li><a href="#introduction">Introduction</a></li>
          <li><a href="#neighborhoods">Neighborhoods</a></li>
          <li><a href="#housing">Housing</a></li>
          <li><a href="#mikvaot">Mikvaot</a></li>
          <li><a href="#kosher-food">Kosher Food</a></li>
          <li><a href="#networking">Networking</a></li>
          <li><a href="#news">News</a></li>
          <li><a href="#schools">Schools</a></li>
          <li><a href="#visiting">Visiting</a></li>
          <li><a href="#resources">Resources</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header; 