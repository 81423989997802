import React from 'react';

function Housing() {
  return (
    <section id="housing" className="section">
      <h2>A Note on Housing</h2>
      <div className="housing-content">
        <div className="housing-info">
          <p>
            Housing in Seattle is tough -- both to rent and to buy. There is simply not much availability in desirable neighborhoods and the prices are high. In neighborhoods with denser housing such as apartment complexes (Ravenna, Columbia City, downtown), it can be easier to find a place to live. Many young professionals go with the "in-law" unit approach of living in a separate unit within another family's house. Zillow is a personal favorite for finding available housing, e.g., an approximate <a href="https://www.zillow.com/homes/for_rent/1_fr/0_fs/house,condo,apartment_duplex,mobile,townhouse_type/5331158e16X1-CR1vjtauhtoda4e_xlf96_crid/1.0-_baths/1_laundry/0_singlestory/featured_sort/47.694024,-122.262597,47.660679,-122.313151_rect/X1-SS1yzdfcfcodu3_7x5fi_sse/" target="_blank" rel="noopener noreferrer">map</a> of rentals in the North End eruv.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Housing; 