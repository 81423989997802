import React from 'react';

function Schools() {
  return (
    <section id="schools" className="section">
      <h2>Schools</h2>
      <div className="schools-content">
        <div className="schools-info">
          <p>
            Seattle has a number of Jewish educational institutions ranging from daycare to high school.
            The Samis Foundation is a local supporter of Jewish Day Schools, and maintains a list{' '}
            <a href="https://samisfoundation.org/jewish-schools/" target="_blank" rel="noopener noreferrer">here</a>.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Schools; 