import React from 'react';

function KosherFood() {
  return (
    <section id="kosher-food" className="section">
      <h2>Kosher Food</h2>
      <div className="kosher-food-content">
        <div className="kosher-restaurants">
          <h3>Restaurants</h3>
          <p>
            The Seattle Vaad maintains an updated list of kosher establishments at{' '}
            <a href="http://seattlevaad.org/kosher-portfolio/" target="_blank" rel="noopener noreferrer">
              http://seattlevaad.org/kosher-portfolio/
            </a>. The Mary Schwartz Summit at First Hill (retirement living) has a Kosher dairy cafe that offers takeout during weekday lunch hours (11:30AM-2PM).
          </p>
        </div>
        
        <div className="kosher-groceries">
          <h3>Groceries</h3>
          <p>
            QFC and Trader Joe's both provide many kosher fixings, including meats, cheeses, and other products. <a href="https://www.qfc.com/stores/details/705/00807?cid=loc70500807P_other" target="_blank" rel="noopener noreferrer">QFC in University Village</a> has an in-house meat cutter who often posts discounts on the <a href="http://facebook.com/groups/269960016533787" target="_blank" rel="noopener noreferrer">Kosher Deals in Seattle</a> FB group. It also features kosher rotisserie chickens, sushi, and a kosher <a href="http://seattlevaad.org/portfolio/einstein-brothers-bagels/" target="_blank" rel="noopener noreferrer">Einstein Brothers</a> bagel shop where you can pick up fresh challah. <a href="https://local.safeway.com/safeway/wa/seattle/9262-rainier-ave-s.html" target="_blank" rel="noopener noreferrer">Safeway</a> locations on Rainier Ave near Seward Park and on 35th in Wedgwood have a growing supply of fresh meat, cheeses, and more. <a href="https://www.pccmarkets.com/" target="_blank" rel="noopener noreferrer">PCC</a>, a local upscale natural food store, carries an assortment of kosher products including cheeses.
          </p>
          <p>
            <a href="https://seattlekosher.com/" target="_blank" rel="noopener noreferrer">Seattle Kosher</a> delivers online purchases of many kosher brands. Costco (originally founded in the Seattle area!) and Grocery Outlet are good places to find bargains. <a href="https://www.yelp.com/biz/dk-market-renton" target="_blank" rel="noopener noreferrer">DK's</a> is a huge warehouse market in Renton that carries a number of kosher imported products and lots of excellent produce.
          </p>
        </div>
        
        <div className="kosher-catering">
          <h3>Catering</h3>
          <ul>
            <li>
              <strong>Leah's Catering</strong> - Traditional kosher catering:{' '}
              <a href="https://www.leahcookskosher.com/catering.php" target="_blank" rel="noopener noreferrer">
                Website
              </a>
            </li>
            <li>
              <strong>Dalia's Catering</strong> - caters delightful Israeli and Sephardic menus out of Seward Park:{' '}
              <a href="http://seattlevaad.org/dalias-catering/" target="_blank" rel="noopener noreferrer">
                Dalia's Catering
              </a>
            </li>
            <li>
              <strong>Epic Eats by Eli:</strong>{' '}
              <a href="https://www.epiceatsbyeli.com/" target="_blank" rel="noopener noreferrer">
                https://www.epiceatsbyeli.com/
              </a>
            </li>
            <li>
              <strong>Francine's Desserts</strong> - Custom cakes/pastries and holiday/Shabbat catering:{' '}
              <a href="https://www.instagram.com/francinesdesserts" target="_blank" rel="noopener noreferrer">Instagram</a>
              {' • '}
              <a href="https://www.facebook.com/FrancinesDesserts/" target="_blank" rel="noopener noreferrer">Facebook</a>
            </li>
            <li>
              <strong>The Summit:</strong>{' '}
              <a href="https://www.klinegalland.org/our-care/the-summit-at-first-hill/culinary-services/" target="_blank" rel="noopener noreferrer">
                https://www.klinegalland.org/our-care/the-summit-at-first-hill/culinary-services/
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default KosherFood; 