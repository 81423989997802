import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Introduction from './components/Introduction';
import Neighborhoods from './components/Neighborhoods';
import Housing from './components/Housing';
import Mikvaot from './components/Mikvaot';
import KosherFood from './components/KosherFood';
import Networking from './components/Networking';
import News from './components/News';
import Schools from './components/Schools';
import Visiting from './components/Visiting';
import Resources from './components/Resources';
import Footer from './components/Footer';
import AdUnit from './components/AdUnit';
import { trackPageView } from './utils/Analytics';

function App() {
  useEffect(() => {
    // Track initial page view
    trackPageView(window.location.pathname);
  }, []);

  return (
    <div className="App">
      <Header />
      <main>
        <article itemScope itemType="http://schema.org/Guide">
          <meta itemProp="author" content="Dina Levitan" />
          <meta itemProp="description" content="A comprehensive guide to Jewish life in Seattle, WA" />
          <Introduction />
          <div className="ad-container">
            <AdUnit 
              style={{ 
                margin: '2rem auto',
                minHeight: '250px', // Reserve space for ad
                maxWidth: '800px'
              }}
            />
          </div>
          <Neighborhoods />
          <Housing />
          <Mikvaot />
          <KosherFood />
          <Networking />
          <News />
          <Schools />
          <Visiting />
          <Resources />
          <div className="ad-container">
            <AdUnit 
              style={{ 
                margin: '2rem auto',
                minHeight: '250px', // Reserve space for ad
                maxWidth: '800px'
              }}
            />
          </div>
        </article>
      </main>
      <Footer />
    </div>
  );
}

export default App;
