import React from 'react';

function News() {
  return (
    <section id="news" className="section">
      <h2>News</h2>
      <div className="news-content">
        <div className="news-info">
          <p>
            <a href="https://thecholent.substack.com/" target="_blank" rel="noopener noreferrer">The Cholent</a> is Seattle's only source for Jewish news, delivered straight to your inbox.
            Run by former Jewish in Seattle editor Emily Alhadeff, The Cholent provides reporting, analysis, and interviews on issues as they pertain to our little Jewish corner of the planet.
          </p>
        </div>
      </div>
    </section>
  );
}

export default News; 