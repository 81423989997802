import React, { useEffect, useRef } from 'react';

const AdUnit = ({ style = {} }) => {
  const adRef = useRef(null);
  
  useEffect(() => {
    // Create intersection observer to load ad only when visible
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Load the ad when it becomes visible
            try {
              (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (error) {
              console.error('Error loading AdSense ad:', error);
            }
            // Disconnect observer after loading
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 } // Trigger when 10% of the ad is visible
    );

    if (adRef.current) {
      observer.observe(adRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const adStyle = {
    display: 'block',
    textAlign: 'center',
    ...style
  };

  return (
    <ins
      ref={adRef}
      className="adsbygoogle"
      style={adStyle}
      data-ad-client="ca-pub-6409886723625279"
      data-ad-slot="5016317094"
      data-ad-layout="in-article"
      data-ad-format="fluid"
    />
  );
};

export default AdUnit; 