import React, { useState, useEffect, useRef } from 'react';

function Map() {
  const [isLoaded, setIsLoaded] = useState(false);
  const mapRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsLoaded(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '50px' }
    );

    if (mapRef.current) {
      observer.observe(mapRef.current);
    }

    return () => {
      if (mapRef.current) {
        observer.unobserve(mapRef.current);
      }
    };
  }, []);

  return (
    <div className="map-container" ref={mapRef}>
      <h3>Jewish Institutions in Seattle</h3>
      <p>
        <a 
          href="https://www.google.com/maps/d/viewer?mid=1NKQwLsn239M_xZVp7yoFvD8a3P0_5V1r&usp=sharing" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          View full map
        </a>
      </p>
      <div className="map-frame">
        {isLoaded && (
          <iframe
            src="https://www.google.com/maps/d/embed?mid=1NKQwLsn239M_xZVp7yoFvD8a3P0_5V1r&ehbc=2E312F"
            title="Jewish Institutions in Seattle"
            loading="lazy"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            referrerPolicy="no-referrer-when-downgrade"
          />
        )}
      </div>
    </div>
  );
}

export default Map; 