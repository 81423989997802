// Analytics utility functions
export const trackPageView = (path) => {
  if (window.gtag) {
    window.gtag('config', 'G-99973NKMH8', {
      page_path: path
    });
  }
};

export const trackEvent = (category, action, label = null, value = null) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    });
  }
}; 