import React, { useState, useEffect, useRef } from 'react';
import Map from './Map';

// Introduction component with welcome message for Jewish families in Seattle
function Introduction() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsImageLoaded(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: '50px' }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <>
      <section className="introduction">
        <div className="introduction-content">
          <div className="introduction-image-container" ref={imageRef}>
            {isImageLoaded && (
              <img
                src="/images/SeattleView.jpg"
                alt="Seattle skyline"
                loading="lazy"
                decoding="async"
                width="800"
                height="450"
              />
            )}
          </div>
          <div className="introduction-text">
            <p>
              <em>
                So you are considering or have just moved to Seattle, and are wondering about all that the Jewish community has to offer. You shouldn't have to figure it all out alone! Read on for words of wisdom and links that should help you navigate the dynamic and glorious Jewish communities of Seattle. Enjoy, and happy exploring!
              </em>
            </p>

            <hr className="section-divider" />

            <p>
              <strong>
                Any questions, comments, or feedback?
                <br />
                Contact me{' '}
                <a href="https://goo.gl/forms/DJWsSQiVW1dVNlYS2" target="_blank" rel="noopener noreferrer">
                  here
                </a>, or check out my website at{' '}
                <a href="http://dinalevitan.com" target="_blank" rel="noopener noreferrer">
                  dinalevitan.com
                </a>.
              </strong>
            </p>
          </div>
        </div>
      </section>
      <section className="map-section">
        <Map />
      </section>
    </>
  );
}

export default Introduction; 