import React from 'react';

function Resources() {
  return (
    <section id="resources" className="section">
      <h2>Other Resources</h2>
      <div className="resources-content">
        <div className="resources-info">
          <p>
            This is an unofficial guide targeted at young Jewish professionals, and only reflects the opinions of its author.
            For alternative guides, see:
          </p>
          <ul>
            <li>
              The <i>Jewish In Seattle</i> magazine publishes its "<a href="http://online.fliphtml5.com/nxcu/dmxd/" target="_blank" rel="noopener noreferrer">Guide to Jewish Washington</a>" yearly, which provides a comprehensive guide of nearly every Jewish organization in the area.
            </li>
            <li>
              <a href="https://communities.ou.org/community/YcBY1aFxys" target="_blank" rel="noopener noreferrer">OU Communities Guide entry for Seattle</a>
            </li>
            <li>
              <a href="https://samisfoundation.org/seattle-jewish-communities/" target="_blank" rel="noopener noreferrer">Samis Foundation description of the neighborhoods</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Resources; 