import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-info">
          <h3>Support the Community</h3>
          <p>
            This guide is maintained to help newcomers and visitors navigate Jewish life in Seattle.
            If you found this useful, please consider:
          </p>
          <ul className="support-options">
            <li>Making a donation to a Seattle Jewish institution of your choice</li>
            <li>Supporting local kosher establishments</li>
            <li>Participating in community events and programs</li>
            <li>Sharing this resource with others who might benefit</li>
          </ul>
        </div>
        
        <div className="footer-contact">
          <h3>Contact</h3>
          <p>
            For questions or feedback, please use the{' '}
            <a href="https://goo.gl/forms/DJWsSQiVW1dVNlYS2" target="_blank" rel="noopener noreferrer">
              contact form
            </a>{' '}
            or visit{' '}
            <a href="http://dinalevitan.com" target="_blank" rel="noopener noreferrer">dinalevitan.com</a>
          </p>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Dina Levitan. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer; 