import React from 'react';

function Neighborhoods() {
  return (
    <section id="neighborhoods" className="section">
      <h2>The Neighborhoods</h2>
      <p>
        There are a number of neighborhoods with Jewish communities in Seattle. For me, a lot of the decision about where to live was based on where I'd be spending most of my waking hours. The geography and traffic of Seattle make it tough to commute across the lake or across downtown. The greatest concentration of traditionally observant Jews are in the neighborhoods listed below.
      </p>
      
      <div className="neighborhoods-content">
        <div className="neighborhood">
          <h3>The North End</h3>
          <p>
            The neighborhood of Northeast Seattle generally comprises the neighborhoods of Ravenna, Bryant, and Wedgwood close to the University of Washington (<a href="https://www.google.com/maps/d/u/0/viewer?mid=1lMnRg7yZCQHXA7zJmAg_DtkFfe8&amp;hl=en_US" target="_blank" rel="noopener noreferrer">map of the Eruv</a>). It's a safe, family-friendly and walkable neighborhood. It has a wide diversity of Jewish organizations, including:
          </p>
          <ul>
            <li>
              <strong>Mercaz Seattle</strong>
              <p><a href="http://www.mercazseattle.org/" target="_blank" rel="noopener noreferrer">Mercaz</a> is a Modern Orthodox welcoming, growing "startup minyan" that meets on Shabbat and for other special events. It's got a homey feel (it meets outdoors/in a community member's basement) and a significant proportion of young professionals and families. Mercaz is known for its musical melave malkas and retreats such as the yearly Shavuoton.</p>
            </li>
            <li>
              <strong>Congregation Shaarei Tefilah Lubavitch</strong>
              <p><a href="http://www.cstlseattle.org/" target="_blank" rel="noopener noreferrer">CSTL</a> is a Chabad Orthodox synagogue that welcomes Jews of all levels of observance. Whether it's an authentic Shabbos experience with a delicious hot chulent, a classy event with the Seattle Jewish Women's Circle, family friendly Jewish holiday programming, or the only Men's & Women's Seattle Mikveh in town, CSTL has you covered!</p>
            </li>
            <li>
              <strong>Congregation Beth Shalom</strong>
              <p><a href="https://bethshalomseattle.org/" target="_blank" rel="noopener noreferrer">Beth Shalom</a> is a large Conservative shul with many programs specifically for young adults (<a href="https://www.facebook.com/groups/967968203332354/" target="_blank" rel="noopener noreferrer">FB group</a>). They have a lot of innovative programming such as their 50th anniversary series of home-hosted dinner parties.</p>
            </li>
            <li>
              <strong>Temple Beth Am</strong>
              <p><a href="https://www.templebetham.org/" target="_blank" rel="noopener noreferrer">Beth Am</a> is a reform temple that welcomes the entire spectrum of Jewish community and enables each member to reflect the holy: people of all ages, races, and abilities; interfaith families; and people who are single, partnered, straight, lesbian, gay, bisexual, and transgender.</p>
            </li>
            <li>
              <strong>Emanuel Congregation</strong>
              <p><a href="http://www.emanuelcongregation.org/" target="_blank" rel="noopener noreferrer">Emanuel Congregation</a> has been a presence in North Seattle for over 50 years. Emanuel approaches prayer using a traditional format while using an egalitarian minyan approach. More for everyone!</p>
            </li>
            <li>
              <strong>Selah Seattle Minyan</strong>
              <p><a href="https://www.facebook.com/SelahSeattleMinyan/" target="_blank" rel="noopener noreferrer">Selah Seattle</a> is an independent Egalitarian minyan that meets monthly in people's homes for Friday night services and a vegetarian potluck.</p>
            </li>
            <li>
              <strong>JConnect Seattle/UW Hillel</strong>
              <p>The <a href="https://www.hilleluw.org/" target="_blank" rel="noopener noreferrer">University of Washington Hillel</a> has a beautiful facility with a kosher, supervised kitchen. It hosts events for young adults through <a href="https://www.facebook.com/JconnectSeattle/" target="_blank" rel="noopener noreferrer">JConnect</a>, including holiday celebrations (kosher for Passover meals), social events, and guest speakers.</p>
            </li>
          </ul>
        </div>

        <div className="neighborhood">
          <h3>Seward Park</h3>
          <p>
            The Seward Park neighborhood is named after a beautiful park on a Lake Washington peninsula that is shaped like a cactus. It is the largest Orthodox Jewish community in Seattle and one of 3 with an <a href="https://www.google.com/maps/d/u/0/viewer?mid=1VLs2UWctfVGRPb06Y9HD9GTXuFE&amp;hl=en" target="_blank" rel="noopener noreferrer">eruv</a>. You'll often find members of the Jewish community "walking the loop" of the park on holiday afternoons. The neighborhood has a strong Sephardic and Ashkenazi Orthodox presence. The community prides itself on its hachnasat orchim (welcoming of guests and visitors).
          </p>
          
          <h4>Sephardic</h4>
          <p>
            Seward Park is home to the oldest of the Jewish communities in Seattle. Seattle hosts a vibrant Sephardic population that moved to Seattle from Greece and Turkey at the turn of the century. The <a href="http://www.seattlesephardicnetwork.org/" target="_blank" rel="noopener noreferrer">Seattle Sephardic Network</a> organizes community events. There's even a department of <a href="http://jewishstudies.washington.edu/sephardic-studies/" target="_blank" rel="noopener noreferrer">Sephardic Studies at the University of Washington</a>. Sephardic synagogues in Seward Park include <a href="http://www.sbhseattle.org/" target="_blank" rel="noopener noreferrer">Sephardic Bikur Holim</a> (Turkish) and <a href="http://www.ezrabessaroth.net/" target="_blank" rel="noopener noreferrer">Ezra Bessaroth</a> (Rhodesli). Hazzan Emeritus Ike Azose is an institution, having written the siddur and other holy prayer books used by both these congregations. They host awesome cultural events such as the Tu B'shvat Fruticas celebration and Bazaars where many homemade Sephardic goodies are sold.
          </p>
          
          <h4>Ashkenazi</h4>
          <p>
            <a href="http://bcmhseattle.org/" target="_blank" rel="noopener noreferrer">Bikur Cholim Machzikay Hadath</a> is an Ashkenazi Orthodox shul known for its extensive kids/groups programming. The congregation is over 100 years old and has beautiful stained glass windows in the synagogue. <a href="https://www.minyanohrchadash.org/" target="_blank" rel="noopener noreferrer">Minyan Ohr Chadash</a> is a Modern Orthodox minyan where singing and group participation are integral parts of the services. These synagogues are very family-friendly.
          </p>
        </div>

        <div className="neighborhood">
          <h3>Mercer Island</h3>
          <p>
            Mercer Island is the "Beverly Hills" of the Seattle area. It's home to the <a href="https://sjcc.org/" target="_blank" rel="noopener noreferrer">Stroum JCC</a>, which hosts lots of Jewish events including everything from concerts by Israeli musicians to the annual Challah Bake. There's an Orthodox Synagogue called <a href="https://www.islandsynagogue.org/" target="_blank" rel="noopener noreferrer">Island Synagogue</a> as well as a large Conservative synagogue, <a href="http://h-nt.org/" target="_blank" rel="noopener noreferrer">Herzl Ner-Tamid</a>. Mercer Island has an <a href="https://www.islandsynagogue.org/eruv.html" target="_blank" rel="noopener noreferrer">eruv</a>. The QFC on Mercer Island has a deli and a baked goods section.
          </p>
        </div>

        <div className="neighborhood">
          <h3>Downtown/Capitol Hill</h3>
          <p>
            Jews who live in Downtown often take part in the <a href="https://www.facebook.com/CYPSeattle/" target="_blank" rel="noopener noreferrer">Chabad Young Professionals</a> community. Additionally, there has sometimes been a traditional Orthodox <a href="http://capitolhillminyan.org/Welcome.html" target="_blank" rel="noopener noreferrer">service</a> that takes place at the Summit at First Hill (retirement community) on Shabbat mornings.
          </p>
          <p>
            Temple De Hirsch Sinai in Capitol Hill has events specifically targeted towards Young Adults with <a href="https://www.facebook.com/TDHS-The-Tribe-1502448313142110/" target="_blank" rel="noopener noreferrer">TDHS - The Tribe</a>.
          </p>
          <p>
            The <a href="https://www.jewishinseattle.org/" target="_blank" rel="noopener noreferrer">Jewish Federation</a> is located downtown, and has an organization called <a href="https://www.facebook.com/PAVEseattle/" target="_blank" rel="noopener noreferrer">PAVE</a> for Jews in their 30s-40s. The Federation hosts volunteer and other social events for the greater Seattle community.
          </p>
        </div>

        <div className="neighborhood">
          <h3>Chabad</h3>
          <p>
            There are many Chabad communities throughout <a href="https://www.chabad.org/jewish-centers/state/Washington" target="_blank" rel="noopener noreferrer">Washington state</a>. In Seattle, there are Chabad houses in Queen Anne, Shoreline, Northgate, Ballard, etc., while on the East Side there are in Bellevue, Kirkland, Issaquah, and I expect more to come in future.
          </p>
        </div>
      </div>
    </section>
  );
}

export default Neighborhoods; 